import React, {useEffect, useState} from 'react';
import loading from './loading.svg';
import './App.css';

interface JokeResponse {
    id: string,
    joke: string,
    status: number,
}

async function getJoke(): Promise<string> {
    const res = await fetch('https://icanhazdadjoke.com', {
        headers: {
            'Accept': 'application/json',
        },
    });
    if (res.ok) {
        const joke: JokeResponse = await res.json();
        return joke.joke;
    } else {
        throw new Error(`Error getting joke; server responded with code ${res.status}`);
    }
}

function App() {
    const [joke, setJoke] = useState<string | null>(null);

    function newJoke() {
        setJoke(null);
        getJoke()
            .then(setJoke)
            .catch((e) => {
                console.error(e);
                setJoke('Error getting joke, please try again!');
            });
    }

    useEffect(() => {
        newJoke();
    }, [])

    return (
        <div className="container">
            <div className="joke">
                {joke ? (
                    <h1>{joke}</h1>
                ) : (
                    <img src={loading} alt="Loading..." height={100} />
                )}
            </div>
            <button onClick={newJoke}>New joke please!</button>
        </div>
    );
}

export default App;
